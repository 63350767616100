import BottomNav from '@blok/bottom-nav'
import TopNav from '@blok/top-nav'
import { PageContextProvider } from '@context'
import PageContextProfiler from '@helpers/page-context-profiler'
import findAndAssignH1 from '@helpers/find-and-assign-h1'
import renderBlok from '@renderBlok'
import { graphql } from 'gatsby'
import React from 'react'

const pageComponentsWithH1s = {
  detailsPage: 'title',
  eventDetail: 'title',
  meetupTopic: 'topicName',
  resourceDetail: 'title',
}

const StoryblokEntry = ({ data, location, pageContext }) => {
  const prepareStory = () => {
    const story = { ...data.story }
    const topNav = { ...data.topNav }
    const bottomNav = { ...data.bottomNav }
    const resourceStories = pageContext.resourceStories
    const webinarsStories = pageContext.webinarsStories
    const resourceForm = { ...pageContext.resourceForm }
    const eventsForm = { ...pageContext.eventsForm }
    const meetUpFormCtaModule = { ...pageContext.meetUpFormCtaModule }
    const meetUps = pageContext.meetUps
    const alternates = pageContext.alternates
    const tagAlternates = pageContext.tagAlternates
    const isLocalizedStory = pageContext.isLocalizedStory
    const eventStories = pageContext.eventStories
    const priceData = pageContext.priceData
    const subNav = data.subNav && JSON.parse(data.subNav.content)
    const redirectModal =
      pageContext.redirectModal && JSON.parse(pageContext.redirectModal.content)
    const formModal =
      pageContext.formModal && JSON.parse(pageContext.formModal.content)

    story.content = JSON.parse(story.content)
    const pageComponentHasH1 =
      !!story.content[pageComponentsWithH1s[story.content.component]]
    story.content.body = findAndAssignH1(story.content.body, pageComponentHasH1)
    story.content.fullSlug = story.full_slug

    topNav.content = JSON.parse(topNav.content)
    bottomNav.content = JSON.parse(bottomNav.content)
    const resType = typeof resourceForm.content
    resourceForm.content =
      resType !== 'undefined' && JSON.parse(resourceForm.content)
    const eventType = typeof eventsForm.content
    eventsForm.content =
      eventType !== 'undefined' ? JSON.parse(eventsForm.content) : null
    const meetUpFormCtaModuleType = typeof meetUpFormCtaModule.content
    meetUpFormCtaModule.content =
      meetUpFormCtaModuleType !== 'undefined'
        ? JSON.parse(meetUpFormCtaModule.content)
        : null

    return {
      story,
      topNav,
      bottomNav,
      resourceStories,
      resourceForm,
      webinarsStories,
      eventsForm,
      eventStories,
      priceData,
      subNav,
      redirectModal,
      isLocalizedStory,
      alternates,
      formModal,
      tagAlternates,
      meetUpFormCtaModule,
      meetUps,
    }
  }

  let {
    story: { content, ...otherProps },
    topNav: { content: topNav },
    bottomNav: { content: bottomNav },
    resourceStories,
    eventStories,
    priceData,
    resourceForm: { content: resourceForm },
    meetUpFormCtaModule: { content: meetUpFormCtaModule },
    meetUps,
    webinarsStories,
    redirectModal,
    eventsForm: { content: eventsForm },
    subNav,
    isLocalizedStory,
    alternates,
    formModal,
    tagAlternates,
  } = prepareStory()

  return (
    <PageContextProvider
      {...otherProps}
      resources={resourceStories}
      events={eventStories}
      defaultPriceData={priceData}
      resourceForm={resourceForm}
      meetUpFormCtaModule={meetUpFormCtaModule}
      meetUps={meetUps}
      webinars={webinarsStories}
      eventsForm={eventsForm}
      location={location}
      isLocalizedStory={isLocalizedStory}
      alternates={alternates}
      tagAlternates={tagAlternates}
    >
      {process.env.NODE_ENV !== 'production' && (
        <PageContextProfiler pageContext={pageContext} />
      )}
      {topNav && (
        <TopNav
          subNav={subNav}
          blok={topNav}
          removeNavLogoLink={content.removeNavLogoLink}
          removeSubNav={content.removeSubNav}
          removeTopBanner={content.removeTopBanner}
          customTopBanner={content.customTopBanner}
          customNavLogin={content.customNavLogin}
          invertedNav={content.invertedNav}
          topNavPhone={content.topNavPhone}
          topNavCTA={content.topNavCTA}
          alternates={alternates}
          isLandingPage={content.isLandingPage}
        ></TopNav>
      )}
      {renderBlok(content, {
        redirectModal: redirectModal,
        formModal: formModal,
      })}
      {bottomNav && !content.removeBottomNav && (
        <BottomNav blok={bottomNav}></BottomNav>
      )}
    </PageContextProvider>
  )
}

export default StoryblokEntry

export const query = graphql`
  query (
    $storyId: String!
    $topNavId: String!
    $bottomNavId: String!
    $subNavId: String!
  ) {
    story: storyblokEntry(id: { eq: $storyId }) {
      id
      name
      created_at
      uuid
      slug
      field_component
      full_slug
      lang
      content
      is_startpage
      parent_id
      group_id
      first_published_at
      alternates {
        full_slug
        id
        name
        parent_id
        slug
        published
        is_folder
      }
    }
    topNav: storyblokEntry(id: { eq: $topNavId }) {
      id
      name
      created_at
      uuid
      slug
      field_component
      full_slug
      lang
      content
      is_startpage
      parent_id
      group_id
      first_published_at
      alternates {
        full_slug
        id
        name
        parent_id
        slug
        published
        is_folder
      }
    }
    bottomNav: storyblokEntry(id: { eq: $bottomNavId }) {
      id
      name
      created_at
      uuid
      slug
      field_component
      full_slug
      lang
      content
      is_startpage
      parent_id
      group_id
      first_published_at
      alternates {
        full_slug
        id
        name
        parent_id
        slug
        published
        is_folder
      }
    }
    subNav: storyblokEntry(id: { eq: $subNavId }) {
      id
      name
      created_at
      uuid
      slug
      field_component
      full_slug
      lang
      content
      is_startpage
      parent_id
      group_id
      first_published_at
      alternates {
        full_slug
        id
        name
        parent_id
        slug
        published
        is_folder
      }
    }
  }
`
