import { isInBrowser } from '@helpers'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

/**
 * Calculate actual size of the object
 * More info: https://stackoverflow.com/questions/23318037/size-of-json-object-in-kbs-mbs
 */
function getObjectSize(obj) {
  const objLength = new TextEncoder().encode(JSON.stringify(obj)).length

  return `${(objLength / 1024).toFixed(2)}`
}

function renderContextObject(obj, totalSize) {
  const arr = []

  Object.keys(obj).forEach((item) => {
    const size = getObjectSize(obj[item])
    const percentage = ((size / totalSize) * 100).toFixed(2)

    arr.push(<div>{`${item}: ${size}kb (${percentage}%)`}</div>)
  })

  return arr
}

export default function PageContextProfiler({ pageContext }) {
  const [isHidden, setIsHidden] = useState(true)
  const pageContextSize = getObjectSize(pageContext)

  /**
   * Use hotkeys to toggle GUI
   * 'option + shift + p'(∏) or 'option + shift + k'()
   */
  function handleHotkey(event) {
    event.preventDefault()

    if (event.key === '∏' || event.key === '') setIsHidden(!isHidden)
  }

  useEffect(() => {
    if (isInBrowser) {
      document.addEventListener('keyup', handleHotkey, true)
    }

    return () => {
      document.removeEventListener('keyup', handleHotkey, true)
    }
  }, [handleHotkey])

  return (
    <>
      {!isHidden && (
        <div
          style={{
            backgroundColor: '#fff',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '16px',
            position: 'fixed',
            right: '16px',
            top: '16px',
            width: '380px',
            zIndex: '10000',
          }}
        >
          <div>
            Current pageContext size:{' '}
            <strong style={{ color: pageContextSize > 500 ? 'red' : 'green' }}>
              {pageContextSize}kb
            </strong>
            .
          </div>
          <div>
            <pre
              style={{
                backgroundColor: '#f2f2f2',
                borderRadius: '5px',
                display: 'block',
                fontSize: '13px',
                margin: '0',
                maxHeight: '55vh',
                overflow: 'scroll',
                padding: '12px 16px',
                width: '100%',
              }}
            >
              {renderContextObject(pageContext, pageContextSize)}
            </pre>
          </div>
          <div>
            <button onClick={() => console.log(pageContext)}>
              Log to console
            </button>
          </div>
        </div>
      )}
    </>
  )
}

PageContextProfiler.propTypes = {
  pageContext: PropTypes.object.isRequired,
}
